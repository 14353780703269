@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500&display=swap);
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
  background: #ffff;
  font-family: 'Fjalla One', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 15px;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1; 
  }
 body ::-webkit-scrollbar-thumb {
  background: #888; 
  }
  body ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

/*  */
  #181818 black text
  #FDB827 yellow header
  #FD3A69 pink
  #E5E5E5 white bg
   */

   /*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.megamenu {
  position: static!important;
}
.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
}
/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/
/* body {
  background: #eaafc8!important;
  background: -webkit-linear-gradient(to right, #eaafc8, #654ea3)!important;
  background: linear-gradient(to right, #eaafc8, #654ea3)!important;
  min-height: 100vh;
} */
code {
  color: #745eb1;
  background: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 0.2rem;
}
.text-uppercase {
  letter-spacing: 0.08em;
}
.MegaMenuDiv{
  background: center center 
  url(https://therichpost.com/wp-content/uploads/2020/07/Create-your-first-page-in-reactjs-with-bootstrap.png)no-repeat; 
  background-size: cover;
}

.navbar-light .navbar-nav .nav-link
{
  color: #000;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 300 !IMPORTANT;
}




/*test mega menu*/

